import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import '../sass/main.scss';
import NomineesArticle from '../components/BlockLibrary/HamboneAward/NomineesArticle';

class WackyBlogTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          const article = get(this.props, 'data.contentfulWackyBlog');
          this.props.setInfoArticle(article.infoArticle);

          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
     }

     render() {
          const article = get(this.props, 'data.contentfulWackyBlog');
          
          const path = get(this.props, '*');
          const seoTitleTag = article.seoTitleTag ? article.seoTitleTag : `${article.breadcrumbValue} | Pet Health Insurance & Tips`;
          const seoMetaDescription = article.seoMetaDescription ? article.seoMetaDescription.seoMetaDescription : article.shortDescription;

          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = article.trackingCode != null ? article.trackingCode : trackingCodeDefault.trackingCode;

          return (
               <Layout>
                    <Seo
                         pageName={article.omniturePageNameLegacy || article.contentName}
                         specificPhone={article.specificPhone}
                         trackingCode={trackingCode}
                         title={seoTitleTag}
                         path={path}
                         canonical={article.seoCanonicalUrl ? article.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={seoMetaDescription}
                         robots={article.robotsDirective ? article.robotsDirective.robotsDirective : ''}
                         image={article.socialImage ? article.socialImage : article.featuredImage}
                         // category={article.category}
                         article={article}
                         // hasBreadcrumb={true}
                         showAntiFlickerSnippet={article.showAntiFlickerSnippet}
                    />
                    <Header header={article.header}></Header>
                    <main id="main-content" role="main">
                         <NomineesArticle articlePet={article} />
                    </main>
                    <Footer footer={article.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setInfoArticle: (data) => dispatch({ type: `GET_INFO_ARTICLE`, payload: data }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};
const mapStateToProps = (state) => {
     return {
          state
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(WackyBlogTemplate);

export const pageQuery = graphql`
     query WackyBlogBySlug($slug: String!) {
          contentfulWackyBlog(slug: { eq: $slug }) {
               ...ContentfulWackyBlogFields
          }
          getTrackingCodeDefault: contentfulPage(contentful_id: { eq: "wChsWYdrCJ5doI8QdVkBk" }) {
               id
               trackingCode
          }
     }
`;
